import React, { useState } from 'react';
import { Form, Input, Button, Col, Row, Result } from 'antd';
import {
  MailOutlined,
  PhoneOutlined,
  SmileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { postData } from '../../../../utils/services';
import { generateEmailFormat } from '../../../../utils/tools';
import ReactSimpleVerify from 'react-simple-verify';
import 'react-simple-verify/dist/react-simple-verify.css';
import { tintColorLight } from '../../../constants/colors';
import SectionView from '../../common/SectionView';

const ContactForm = () => {
  const { t } = useTranslation();

  const [verifyDone, setVerifyDone] = useState(false);
  const [showResult, setShowResult] = useState<'none' | 'success' | 'error'>(
    'none'
  );

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const formattedInfo = generateEmailFormat(values);
    postData('contact_us', {
      message: formattedInfo,
      message_type: 'contact_us',
    })
      .then((result) => {
        if (result.return_code === 200) {
          setShowResult('success');
        } else {
          setShowResult('error');
        }
      })
      .catch((err) => {
        console.log(err);
        setShowResult('error');
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <SectionView title={t('contact.title')}>
      <Row justify="center">
        <Col span={22} md={12} lg={8}>
          {showResult === 'none' ? (
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="name"
                label={t('contact.name')}
                rules={[
                  {
                    required: true,
                    message: t('contact.errorMsg.null', {
                      field: t('contact.name'),
                    }),
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item
                name="phone"
                label={t('contact.phone')}
                rules={[
                  {
                    required: true,
                    message: t('contact.errorMsg.null', {
                      field: t('contact.phone'),
                    }),
                  },
                ]}
              >
                <Input prefix={<PhoneOutlined />} />
              </Form.Item>
              <Form.Item name="email" label={t('contact.email')}>
                <Input prefix={<MailOutlined />} />
              </Form.Item>
              <Form.Item
                name="message"
                label={t('contact.message')}
                rules={[
                  {
                    required: true,
                    message: t('contact.errorMsg.null', {
                      field: t('contact.message'),
                    }),
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>
              <ReactSimpleVerify
                success={() => {
                  setVerifyDone(true);
                }}
                movedColor={tintColorLight}
                tips={t('verify.tips')}
                successTips={t('verify.done')}
                width={300}
              />
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: 20 }}
                  disabled={!verifyDone}
                >
                  {t('contact.submit')}
                </Button>
              </Form.Item>
            </Form>
          ) : showResult === 'success' ? (
            <Result
              icon={<SmileOutlined />}
              title={t('contact.successMsg.title')}
              subTitle={t('contact.successMsg.subtitle')}
              status="success"
            />
          ) : (
            <Result
              title={t('contact.errorMsg.failSend.title')}
              subTitle={t('contact.errorMsg.failSend.subtitle')}
              status="error"
            />
          )}
        </Col>
      </Row>
    </SectionView>
  );
};

export default ContactForm;
