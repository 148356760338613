import React from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import ContactForm from '../components/pages/contact/ContactForm';
import MapView from '../components/pages/contact/MapView';

const Contact = ({ location }) => {
  const { t } = useTranslation();
  return (
    <>
      <PageContainer pageTitle={t('navMenu.contact')} location={location}>
        <ContactForm />
        <MapView />
      </PageContainer>
    </>
  );
};

export default Contact;
