import React, { useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { useMount } from 'ahooks';
import { createStyle } from '../../../../utils/tools';
import SectionView from '../../common/SectionView';
import { Col, Row, Typography } from 'antd';
import { tintColorLight } from '../../../constants/colors';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const { Title, Paragraph } = Typography;

mapboxgl.accessToken =
  'pk.eyJ1Ijoic3Vubnl4dWUiLCJhIjoiY2tlYnBvOWNmMDA4dTJ6bG40ZWRueGtudSJ9.uPXWp13DLlCbunzPlY5vvQ';

const MapView = () => {
  const { t } = useTranslation();
  const mapRef = useRef(null);

  useMount(() => {
    console.log(mapRef.current);
    const map = new mapboxgl.Map({
      container: mapRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-123.086896, 49.129505],
      zoom: 10,
    });

    const marker1 = new mapboxgl.Marker({ color: tintColorLight })
      .setLngLat([-123.086896, 49.129505])
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML(
            `<img style='width:50px' src='/images/logo-green-vertical.png'/><h3><strong>陆牛商城 Luniu Mall</strong></h3><p>12155 Riverside Way,Richmond, BC, Canada V6W 1K8</p>`
          )
      )
      .addTo(map);
  });
  return (
    <SectionView
      title={t('contact.address')}
      backgroundColor={tintColorLight}
      titleColor="white"
    >
      <Helmet>
        <link
          href="https://api.tiles.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>
      <Row justify="center">
        <Col span={24} md={10}>
          <div ref={mapRef} style={styles.mapContainer} />
        </Col>
        <Col span={24} md={10} style={styles.infoContainer}>
          <div>
            <Title level={4} style={styles.subTitle}>
              {t('footer.office')}
            </Title>
            <Paragraph style={styles.text}>
              12155 Riverside Way,Richmond, BC, Canada
            </Paragraph>
          </div>
          <div>
            <Title level={4} style={styles.subTitle}>
              {t('footer.farm')}
            </Title>
            <Paragraph style={styles.text}>
              3345 224th Street, Langley, BC, Canada
            </Paragraph>
          </div>
          <div>
            <Title level={4} style={styles.subTitle}>
              {t('footer.phone')}
            </Title>
            <a href="tel:1-604-275-9642" style={styles.text}>
              1-604-275-9642
            </a>
          </div>
        </Col>
      </Row>
    </SectionView>
  );
};

const styles = createStyle({
  mapContainer: {
    width: '100%',
    height: 400,
    borderRadius: 15,
  },
  infoContainer: {
    padding: '1rem 2rem',
  },
  subTitle: {
    color: 'white',
  },
  text: {
    color: 'white',
  },
});

export default MapView;
